import axios from './$http'

// 创建分类
export function setColumn(params) {
  return axios.post("/ka-subject", params)
}
// 分页查询分类
export function getColumn(data) {
  return axios.get("/ka-subject", { params: data })
}
// 根据id修改分类
export function putColumnById(data) {
  return axios.put("/ka-subject/" + data.id, data)
}
// 根据id修改分类状态
export function putColumnStatus(id, status) {
  return axios.put("/ka-subject/" + id + "/status/" + status)
}
// 根据id删除分类
export function deleteColumnById(id) {
  return axios.delete("/ka-subject/" + id,)
}

// 根据id查询分类详情
export function getColumnDetailById(id) {
  return axios.get("/ka-subject/detail/" + id)
}