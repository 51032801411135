import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import "./index.less"


const BreadcrumbCom = (props) => {
    const list = props.list
    return (
        <Breadcrumb className="breadcrumb" style={{ padding: "16px" }} separator="-">
            {list.length > 0 && list.map(item => {
                return (
                    <Breadcrumb.Item key={item.title || item}>
                        {item.to ? <Link to={item.to}>{item.title}</Link> : item.title || item}
                    </Breadcrumb.Item>
                )
            })}
        </Breadcrumb>
    )
}

export default BreadcrumbCom;
