import axios from './$http'

// 课程统计
export const statisticsCouse = () => {
  return axios.get("/statistics/course")
}
// 文章统计
export const statisticsArticle = () => {
  return axios.get("/statistics/article")
}
// 
// 文章统计
export const statisticsLecturer = () => {
  return axios.get("/statistics/doctor")
}