import axios from './$http'
//分页查询医生信息
export function doctorList(data) {
    return axios.get("/ka-doctor", { params: data })
}
//创建医生信息
export function Adddoctor(data) {
    return axios.post("/ka-doctor", data)
}
//根据id修改医生信息
export function Putdoctor(data) {
    return axios.put("/ka-doctor/" + data.id, data)
}
//根据id修改医生上架状态
export function putdoctorStatus(id, status) {
    return axios.put("/ka-doctor/" + id + "/status/" + status)
}
//根据id删除医生信息
export function deleteDoctor(id) {
    return axios.delete("/ka-doctor/" + id)
}
//根据id查询医生详情
export function doctorDetail(data) {
    return axios.get("/ka-doctor/detail/" + data.id)
}