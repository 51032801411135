import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import { inject, observer } from 'mobx-react';
import { pathConversionArr, flatten } from '../../libs/until.tool';
import "./index.less";
const SubMenu = Menu.SubMenu
@withRouter
@inject("appStore")
@observer
class Menus extends Component {
    constructor(prop) {
        super(prop)
        this.state = {
            openKeys: []
        }
    }
    componentDidMount() {
        let pathname = this.props.location.pathname
        let { appStore } = this.props
        let submenuKeys = appStore.getSubMenusKeys
        let pathnameList = pathConversionArr(pathname)
        let nowOpenKeys = []
        pathnameList.forEach(item => {
            if (submenuKeys.includes(item)) {
                nowOpenKeys.push(item)
            }
        })
        this.setState({
            openKeys: nowOpenKeys
        })
    }

    onOpenChange = (openKeys) => {
        let len = openKeys.length
        let nowKey = len >= 1 ? openKeys[len - 1] : ""
        let nowOpenKeys = openKeys.filter(item => {
            let reg = new RegExp(item + "/.*")
            return reg.test(nowKey)
        })
        if (nowKey) {
            nowOpenKeys.push(nowKey)
        }
        this.setState({
            openKeys: nowOpenKeys,
        })
    }
    changeBreadcrumb = (e) => {
        let { changeBreadcrumbList } = this.props.appStore
        let { keyPath } = e
        changeBreadcrumbList(keyPath.reverse())
    }
    renderMenu = (menuList) => {
        return menuList.map(item => {
            if (item.subs) {
                return <SubMenu key={item.key} icon={item.icon} title={item.title} style={{ color: "#6A707E" }}>
                    {this.renderMenu(item.subs)}
                </SubMenu>
            }
            return <Menu.Item key={item.key}>
                <Link to={item.key} style={{ color: "#464A53" }}>
                    <div className={item.title === "首页" ? "menu-home" : ''}>
                        {
                            item.icon && <i className={["iconfont " + item.icon]}></i>
                        }
                        <span className="menu-title">{item.title}</span>
                    </div>
                </Link>
            </Menu.Item >
        })
    }
    render() {
        const { menus, location } = this.props
        let { pathname } = location
        let openKeys = menus.map(item => item.key)
        let pathnameArr = pathConversionArr(pathname)
        let flattenMenus = flatten(menus, "subs", true)
        let selectedKeys = pathnameArr.map(item => {
            let f = flattenMenus.filter(menu => {
                return menu.key === item
            })
            return f.length > 0 ? item : ""
        }).filter(item => item)

        return (
            <Menu
                mode="inline"
                theme="light"
                selectedKeys={selectedKeys}
                onClick={(e) => this.changeBreadcrumb(e)}
                openKeys={openKeys}
                onOpenChange={this.onOpenChange}
                className="menus"
            >
                {this.renderMenu(menus)}
            </Menu>
        );
    }
}

export default Menus;
