import axios from './$http'

//查询 首页
export function HomeTags() {
    return axios.get("/portal-tag")
}
//保存 首页标签
export function saveHomeTags(data){
    return axios.put("/portal-tag",data)
}

//所有标签
export function getAllTags(){
    return axios.get("/tag/hospital")
}

export function saveAllTags(data){
    return axios.post("/tag/hospital",data)
}

//栏目下的标签
export function columnTags(data){
    return axios.get("/column-tag/column/"+data.columnId)
}

//保存标签
export function saveTags(data){
    return axios.post("/tag/hospital",data)
}