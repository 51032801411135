import axios from './$http'



//分页查询主题栏目
export function subjectList(data) {
    return axios.get("/ka-subject", { params: data })
}
//分页查询面授课程信息
export function coursePlan(data) {
    return axios.get("/ka-course", { params: data }) // ka-course/detail
}
//创建面授课程信息
export function AddcoursePlan(data) {
    return axios.post("/ka-course", data)
}
//分页查询课程教室信息
export function courseClassroom(data) {
    return axios.get("/ka-course-classroom", { params: data })
}
//修改面授课程信息
export function Putcourse(data) {
    return axios.put("/ka-course/" + data.id, data)
}
//修改面授课程上架状态
export function putCourseStatus(id, status) {
    return axios.put("/ka-course/" + id + "/status/" + status)
}
//根据id查询面授课程详情
export function courseDetail(id) {
    return axios.get("/ka-course/detail/" + id)
}
// 根据id删除面授课程
export function deleteCourse(id) {
    return axios.delete("/ka-course/" + id)
}