import React from 'react';
import { Menu } from 'antd';

export default function DropDownList(props) {

    return (
        <Menu>
            {props.dropdownList.map(item =>
                <Menu.Item onClick={item.click} key={item.title}>
                    {item.icon ? item.icon : ""}{item.title}
                </Menu.Item>)}
        </Menu>
    )

}