import React, { Component } from 'react';


class logo extends Component {
    render() {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                color: "#fff",
                alignItems: "center",
                backgroundColor: "#4782FF",
                height: "64px"
            }}>
                <img width="33px" src={this.props.src} alt="" />
                {
                    <h1
                        style={{
                            marginBottom: 0,
                            marginLeft: 10,
                            fontSize: "16px",
                            color: "#fff"
                        }}
                        className={
                            this.props.collapsed ? "zoomOut" : 'zoomIn'
                        }
                    >
                        {this.props.children}
                    </h1>
                }
            </div>
        );
    }
}

export default logo;