import React, { Component } from 'react';
import { Dropdown, Modal } from 'antd';
import { toJS } from "mobx"
import { inject, observer } from 'mobx-react'
import { FullscreenOutlined, ImportOutlined, DownOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import utils from '../../libs/utils'
import DropdownList from "./dropdownList.jsx"

import './header.less';

@inject("appStore")
@observer


class Header extends Component {
    state = {
        visable: false,
        isFullscreen: false
    }
    componentDidMount() {

    }
    showMolde = () => {
        this.setState({
            visable: true
        })
    }
    handeleCancel = () => {
        this.setState({
            visable: false
        })
    }
    handelOut = () => {
        this.props.appStore.loginOut()
        this.setState({
            visable: false
        })
    }
    onFullscreen = () => {
        let de = document.documentElement;
        if (de.requestFullscreen) {
            this.setState({
                isFullscreen: true
            })
            de.requestFullscreen()
            return
        }
        if (de.webkitRequestFullScreen) {
            this.setState({
                isFullscreen: true
            })
            de.webkitRequestFullScreen()
            return
        }
        if (de.mozRequestFullScreen) {
            this.setState({
                isFullscreen: true
            })
            return
        }

    }
    exitFullscreen = () => {
        let d = document;
        if (d.exitFullscreen) {
            this.setState({
                isFullscreen: false
            })
            d.exitFullscreen();
            return
        }
        if (d.webkitCancelFullScreen) {
            this.setState({
                isFullscreen: false
            })
            d.webkitCancelFullScreen();
            return
        }
        if (d.mozCancelFullScreen) {
            this.setState({
                isFullscreen: false
            })
            d.mozCancelFullScreen();
            return
        }
    }
    render() {
        const dropdownList = [
            //     {
            //     title: "绑定微信",
            //     icon: <DisconnectOutlined />,
            //     click: ""
            // },
            // {
            //     title: "修改密码",
            //     icon: <EditOutlined />,
            //     click: ""
            // },
            {
                title: "退出登录",
                icon: <ImportOutlined />,
                click: this.showMolde
            }]
        const { hospitalLogo } = toJS(this.props.appStore.hospitalInfo)
        let { nickname = "管理员一号", avatarUrl = hospitalLogo || require("../../assets/images/logo.png") } = JSON.parse(utils.getCookies("userInfo") || "{}")
        return (
            <div className="header">
                {/* <CollapsibleBtn collapsed={this.props.collapsed} onToggle={this.props.onToggle} /> */}
                <div></div>
                <div className="header-right">
                    <div className="full-screen-btn pointer" >
                        {this.state.isFullscreen ? <FullscreenExitOutlined onClick={this.exitFullscreen} /> : <FullscreenOutlined onClick={this.onFullscreen} />}

                    </div>
                    <div className="dropdown-list pointer">
                        <Dropdown overlay={<DropdownList dropdownList={dropdownList} />}>
                            <div><img width="30" src={avatarUrl} alt="" /><span className="dropdown-title">{nickname}</span><DownOutlined /></div>
                        </Dropdown>
                    </div>
                </div>
                <Modal
                    title={"退出登录"}
                    visible={this.state.visable}
                    onCancel={this.handeleCancel}
                    onOk={this.handelOut}>
                    你确定退出吗？
                </Modal>
            </div>
        );
    }
}

export default Header;
