import axios from './$http'
//分页查询文章信息
export function article(data) {
    return axios.get("/ka-article", { params: data })
}
//创建文章信息
export function Addarticle(data) {
    return axios.post("/ka-article", data)
}
//根据id修改文章信息
export function putarticle(data) {
    return axios.put("/ka-article/" + data.id, data)
}
//根据id修改文章上架状态
export function putarticlestatus(id, status) {
    return axios.put("/ka-article/" + id + "/status/" + status)
}
//根据id删除文章
export function deleteArticle(id) {
    return axios.delete("/ka-article/" + id)
}
//根据id查询文章详情
export function articleDetail(data) {
    return axios.get("/ka-article/detail/" + data.id)
}

// 获取文章数据统计
export function getArticleRecord(data) {
    return axios.get('/wx/user-article-study/pageByCourseId', {params: data})
}
