import React, { Component } from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import Login from './pages/login';
import Index from './pages/index';
import { inject, observer } from 'mobx-react'
import { getHospitalInfo } from './api';
import './App.css';

@inject('appStore')
@observer

class App extends Component {

  componentDidMount = () => {
    let { pathname } = window.location
    let pathnameArr = pathname.split("/")
    let len = pathnameArr.length
    let code = pathnameArr[len - 2]
    code && getHospitalInfo({
      code
    }).then(res => {
      this.props.appStore.initHospitalInfo(res.data)
      console.log(res);
    }).catch(err => {
      console.log(err);
    })
  }
  render() {
    return (
      <div className="App" >
        <HashRouter>
          <Switch>
            <Route path="/login" component={Login}></Route>
            <Route path="/" component={Index}></Route>
          </Switch>
        </HashRouter>
      </div>
    )
  }
}


export default App;
