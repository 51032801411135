import axios from './$http'

//分页查询课程视频信息
export function courseVideo(data) {
  return axios.get("/ka-course-video", { params: data })
}
//创建课程视频信息
export function addCourseVideo(data) {
  return axios.post("/ka-course-video", data)
}
//根据id修改课程视频信息
export function putVideo(data) {
  return axios.put("/ka-course-video/" + data.id, data)
}
//根据id修改课程视频上架状态
export function putVideoStatus(id, status) {
  return axios.put("/ka-course-video/" + id + "/status/" + status)
}
//根据id查询课程视频详情
export function videoDetail(data) {
  return axios.get("/ka-course-video/detail/" + data.id)
}
// 根据id删除视频课程
export function deleteVideo(data) {
  return axios.delete("/ka-course-video/" + data.id)
}
// 查询观看视频记录
export function getStudyTime(data) {
  return axios.get('/wx/user-course-study/pageByCourseId', {params: data})
}