import React from 'react'
import { Select } from 'antd'
import Cookies from 'js-cookie'
import moment from 'moment'

const Option = Select.Option


export default {
  /*
  * 获取cookies
  * */
  getCookies(key) {
    return Cookies.get(key)
  },
  /*
* 设置Cookies
* */
  setCookies(key, value, expiresTime) {
    if (expiresTime) {
      let seconds = expiresTime
      let expires = new Date(new Date() * 1 + seconds * 1000)
      return Cookies.set(key, value, { expires: expires })
    }
    return Cookies.set(key, value)
  },
  /*
  * 移除Cookies
  * */
  removeCookies(key) {
    return Cookies.remove(key)
  },
  getOptionList(data, isDelAll) {
    if (!data) {
      return [];
    }
    let options = [<Option value="-1" key="d_all">全部</Option>]
    data.forEach((item) => {
      options.push(<Option value={item.id} key={item.id}>{item.name}</Option>)
    })
    if (isDelAll) {
      options.splice(0, 1)
    }
    return options;
  },

  //不拿ID 而是拿对应的值
  getOptionList2(data, isDelAll) {
    if (!data) {
      return [];
    }
    let options = [<Option value="-1" key="d_all">全部</Option>]
    data.forEach((item) => {
      options.push(<Option value={item.name} key={item.id}>{item.name}</Option>)
    })
    if (isDelAll) {
      options.splice(0, 1)
    }
    return options;
  },

  getAllDayList() {
    var dateList = [];
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(startDate.getDate() + 60);
    while ((endDate.getTime() - startDate.getTime()) >= 0) {
      let start = startDate.getTime()
      let month = (startDate.getMonth() + 1).toString().length === 1 ? "0" + (startDate.getMonth() + 1).toString() : (
        startDate.getMonth() + 1);
      let year = startDate.getFullYear()
      let myddy = startDate.getDay();//获取存储当前日期
      var weekday = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
      let day = startDate.getDate().toString().length === 1 ? "0" + startDate.getDate() : startDate.getDate()
      dateList.push({
        value: `${year}-${month}-${day}`,
        id: start
      });
      startDate.setDate(startDate.getDate() + 1);
    }
    return dateList
  },
  // 分页信息
  pagination(data, callback) {
    return {
      onChange: (current) => {
        callback(current)
      },
      current: data.pageNum,
      pageSize: data.pageSize,
      total: data.total,
      showTotal: () => {
        return `共${data.total}条`
      },
      showQuickJumper: true
    }
  },
  // 时间格式转成时间戳
  setDataTime(data, falg) {
    // falg - ture 字符串类型 false number类型
    let time = new Date(data).getTime()
    if (falg) {
      return time.toString()
    }
    return time
  },
  // 时间戳转时间
  getDayTime(data, type) {
    return moment(data).format(type)
  },
  getDaySub: (num, ft = "MM-DD", mt = "days") => {
    return moment().subtract(num, mt).format(ft)
  }
}