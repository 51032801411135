import axios from './$http'
//分页查询消息
export function getmessage(data) {
    return axios.get("/msg-push/back/select-message-push-by-page", {params:data})
}

//创建信息
export function addMessage(data) {
    return axios.post("/msg-push/back/save-message-push",data)
}

//根据id修改信息
export function putMessage(data) {
    return axios.post("/msg-push/back/update-message-push", data)
}

//根据id查询消息
export function messageDetail(data) {
    return axios.get("/msg-push/front/select-message-push/"+data.id)
}

//获取订阅人数
export function messageDing(data){
    return axios.post("/msg-push/back/select-message-push-tag-count",data)
}

//订阅数据
export function messageData(data){
    return axios.post('/msg-push/back/query-message-push',data)
}

//根据id删除消息
export function  delMessage(data){
    return axios.get('/msg-push/front/delete-message-push/'+data.id)
}
//发送消息
export function sendMessage(data) {
    return axios.post('/msg-push/back/send-message-push',data)
}