import React, { Component } from 'react';
import { Form, Input, Button, Modal, message } from 'antd';
import { HashRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { login, getVerificationCode } from '../../api';

const router = new HashRouter()
@inject('appStore')
@observer
class LoginForm extends Component {
    state = {
        codePath: "",
        customerServiceCode: "../../assets/images/customer-service-code.png",
        serviceCodeVisible: false
    }
    formRef = React.createRef()
    componentDidMount = () => {
        this.initCode()
    }
    initCode = () => {
        getVerificationCode().then(res => {
            this.setState({
                codePath: res.data
            })
        }).catch(err => {
            console.log(err);
            // this.initCode()
        })
    }
    onFinish = (val) => {
        login(val).then(res => {
            let { manageToken, ...userInfo } = res.data
            if (manageToken) {
                this.props.appStore.login(userInfo, manageToken)
            }
            message.success("登录成功")
            // router.history.push('/home')
            router.history.push('/home')
        }).catch(err => {

        })
    }
    onFinishFailed = (val) => {

    }
    handleCancel = () => {
        this.setState({
            serviceCodeVisible: false
        })
    }
    showserviceCode = () => {
        this.setState({
            serviceCodeVisible: true
        })
    }
    updateCord = () => {
        this.initCode()
    }
    render() {
        let { codePath, customerServiceCode, serviceCodeVisible } = this.state
        return (
            <div className="from-box">
                <Form
                    name="basic"
                    initialValues={{ readme: true }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                >
                    <Form.Item
                        name="username"
                        rules={[{
                            required: true,
                            message: "请输入账号"
                        }]}
                    >
                        <Input className="input" placeholder="请输入账号" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{
                            required: true,
                            message: "请输入密码"
                        }]}
                        extra={<div onClick={this.showserviceCode} style={{ textAlign: "right", cursor: "pointer", color: "#4782FF" }}>忘记密码？</div>}
                    >
                        <Input.Password className="input" placeholder="请输入密码" />
                    </Form.Item>

                    <Form.Item
                        name="captcha"
                        rules={[{
                            required: true,
                            message: "请输入验证码"
                        }]}
                        style={{ display: "flex", justifyContent: "space-between" }}
                    >
                        <div className="code-box">
                            <Input className="input" placeholder="请输入图形验证码" style={{ width: "162px" }} />
                            <img width="80" height="44" style={{ cursor: "pointer" }} src={codePath} alt="" onClick={this.updateCord} />
                        </div>
                    </Form.Item>
                    {/* <Form.Item name="readme" valuePropName="checked">
                        <Checkbox>同意<a href="/">《共享协议》</a></Checkbox>
                    </Form.Item> */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-submit">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
                <Modal title="扫码联系客服找回密码" visible={serviceCodeVisible} onCancel={this.handleCancel} footer={null}>
                    <div style={{ textAlign: "center" }}>
                        <img src={require("../../assets/images/customer-service-code.png")} alt="" />
                    </div>
                </Modal>
            </div >
        );
    }
}

export default LoginForm;