import React, { Component } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import routes from "./routes.js"

class RenderRoutes extends Component {
  render() {
    return (
      <div className="page-main">
        <Switch>
          {
            routes.map((route, index) => {
              let { component: Com, path, title, redirect, ...rest } = route
              return redirect
                ? <Redirect {...rest} key={path} from={path} to={redirect} />
                : <Route key={(path || Math.random() * 100) + index} {...rest} path={path ? path : ''} render={(props) => {
                  document.title = title ? title : "React-system"
                  return <Com {...props} />
                }} />
            })
          }
        </Switch>
      </div>
    )
  }
}
export default RenderRoutes