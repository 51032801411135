import axios from './$http'

export function getHospitalInfo(params) {
  return axios.get("/ka-hospital/getHospitalByCode", {
    params
  })
}
// 登录
export const login = (params) => {
  return axios.post("/ka-login/login", params)
}
// 获取验证码
export const getVerificationCode = () => {
  return axios.get("/ka-login/randomImage")
}