import React, { Component } from 'react';
import { Tabs } from 'antd';
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import LoginForm from './loginForm';
import "./style.less"
const TabPane = Tabs.TabPane

@inject("appStore")
@observer
class Login extends Component {
    formRef = React.createRef();
    componentDidMount() {

    }
    render() {
        const { appStore } = this.props
        const { hospitalInfo } = appStore
        const { hospitalLogo, hospitalName } = toJS(hospitalInfo)
        return (
            <div className="login-body">
                <div className="bg">
                    <img src="../../assets/images/login-bg.png" alt="" />
                </div>
                <div className="login-form">
                    <div className="login-container">
                        <div className="system-title">
                            <img src={hospitalLogo ? hospitalLogo : require("../../assets/images/logo.png")} alt="" />
                            <h1 className="system-title-h1">{hospitalName ? hospitalName : "健康教育管理平台"}</h1>
                        </div>
                        <div className="login-main">
                            <div className="container">
                                <Tabs defaultActiveKey="1" >
                                    <TabPane tab="账号密码登录" key="1">
                                        <LoginForm ref={this.formRef}></LoginForm>
                                    </TabPane>
                                    {/* <TabPane tab="微信扫码登录" key="2">
                                <div className="iframe-box">
                                    <iframe
                                        title="weixin"
                                        className="login-iframe"
                                        frameBorder="0"
                                        sandbox="allow-scripts allow-same-origin allow-top-navigation"
                                        scrolling="no"
                                        src={`https://open.weixin.qq.com/connect/qrconnect?appid=wx5196c0bea98784f6&redirect_uri=https%3A%2F%2Fmj.yunxiaoos.com%2F%23%2Flogin&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect`} />
                                </div>
                            </TabPane> */}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Login;
