import LoadableComponent from '../components/LoadableComponent'

// const files = require.context("../routes", true, /\.js$/)
// let routesConfig = []
// files.keys().forEach(key => {
//   routesConfig.push({
//     path: key.replace("^./(.*)/index.js$", "$1"),
//     title: files(key).title || "react",
//     component: LoadableComponent(files(key))
//   })
// })
const routes = [
  {
    path: "/home",
    title: "首页",
    component: LoadableComponent(() => import('../routes/Home'))
  },
  {
    path: "/content/carousel",
    title: "课程管理",
    exact: true,
    component: LoadableComponent(() => import('../routes/content/carousel'))
  },
  {
    path: "/content/message",
    title: "消息",
    exact: true,
    component: LoadableComponent(() => import('../routes/content/message'))
  },
  {
    path: "/content/message/addmessage/:id",
    title: "消息新增",
    exact: true,
    component: LoadableComponent(() => import('../routes/content/message/addMessage'))
  },
  {
    path: "/content/carousel/addvideo/:id",
    title: "视频课程新增",
    component: LoadableComponent(() => import('../routes/content/carousel/video/addVideo'))
  },
  {
    path: "/content/carousel/template/addvideo/:id",
    title: "视频模板创建课程",
    component: LoadableComponent(() => import('../routes/content/carousel/video/addVideo'))
  },
  {
    path: "/content/carousel/addUnderline/:id",
    title: "面授课程新增",
    component: LoadableComponent(() => import('../routes/content/carousel/under-line/addUnderline'))
  },
  {
    path: "/content/carousel/template/addUnderline/:id",
    title: "面授模板创建课程",
    component: LoadableComponent(() => import('../routes/content/carousel/under-line/addUnderline'))
  },
  {
    path: "/content/carousel/record/:id",
    title: "数据",
    component: LoadableComponent(() => import('../routes/content/carousel/record/'))
  },
  {
    path: "/content/recommend",
    title: "推荐管理",
    exact: true,
    component: LoadableComponent(() => import('../routes/content/recommend'))
  },
  {
    path: "/content/recommend/addRecommend/:id",
    title: "新增推荐",
    exact: true,
    component: LoadableComponent(() => import('../routes/content/recommend/addRecommend'))
  },
  {
    path: "/content/knowledge",
    title: "文章管理",
    exact: true,
    component: LoadableComponent(() => import('../routes/content/knowledge'))
  },
  {
    path: "/content/knowledge/add/:id",
    title: "文章新增",
    component: LoadableComponent(() => import('../routes/content/knowledge/addKnowledge'))
  },
  {
    path: "/content/knowledge/record/:id",
    title: "数据",
    component: LoadableComponent(() => import('../routes/content/knowledge/record'))
  },
  {
    path: "/noAuthority",
    title: "无权限",
    exact: true,
    component: LoadableComponent(() => import('../routes/ErrorPage/noAuthority.js'))
  },
  {
    path: "/seting/column",
    title: "栏目设置",
    exact: true,
    component: LoadableComponent(() => import('../routes/seting/column'))
  },
  {
    path: "/seting/column/createColumn",
    title: "创建栏目",
    exact: true,
    component: LoadableComponent(() => import('../routes/seting/column/createColumn'))
  },
  {
    path: "/seting/column/createColumn/:id",
    title: "编辑栏目",
    component: LoadableComponent(() => import('../routes/seting/column/createColumn'))
  },
  {
    path: "/seting/tags",
    title: '首页标签',
    exact: true,
    component: LoadableComponent(() => import('../routes/seting/tags'))
  },
  {
    path: "/seting/lecturer",
    title: "讲师设置",
    exact: true,
    component: LoadableComponent(() => import('../routes/seting/lecturer'))
  },
  {
    path: "/seting/lecturer/createLecturer",
    title: "创建讲师",
    exact: true,
    component: LoadableComponent(() => import('../routes/seting/lecturer/createLecturer'))
  },
  {
    path: "/seting/lecturer/createLecturer/:id",
    title: "讲师编辑",
    component: LoadableComponent(() => import('../routes/seting/lecturer/createLecturer'))
  },
  {
    path: "/",
    exact: true,
    redirect: "/home",
  },
  {
    title: "404",
    component: LoadableComponent(() => import('../routes/ErrorPage/404.js'))
  }
]


export default routes