import axios from "./$http";

//视频资源
export function videoResource(data){
    return axios.get('/resource/upload-video-pool/by-current-user',{params:data})
}

//图片资源
export function ImageResource(data){
    return axios.get('/resource/upload-picture-pool/by-current-user', {params:data})
}

//推荐图片资源
export function recommendPicResource(data) {
    return axios.get('/resource/operation-upload-picture-pool/by-current-user',{params:data})
}