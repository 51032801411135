import axios from 'axios'
import config from "./apiurl"
import Cookies from 'js-cookie'
import { HashRouter } from 'react-router-dom'
import { message } from 'antd';
const router = new HashRouter()

//使用create方法创建axios实例
export const request = axios.create({
  timeout: 7000, // 请求超时时间
  baseURL: config.hostUrl,
  method: 'post',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "fe": "zt"
  }
})

// 添加请求拦截器
request.interceptors.request.use(config => {
  const token = Cookies.get("token")
  config.headers['manageToken'] = token
  return config
})

// 添加响应拦截器
request.interceptors.response.use(response => {
  const data = response.data
  switch (data.code) {
    case 10007:
      message.error("登录过期，请重新登录")
      router.history.push('/login')
      return Promise.resolve(data)
    case 20005:
      message.error("验证码错误")
      return
    case 10002:
      message.error("密码错误")
      return
    case 10001:
      message.error("用户名错误")
      return
    case 999999:
      return Promise.reject(data)
    case 200:
      return data
  }
  return Promise.reject(data)
}, error => {
  return Promise.reject(error)
})

export default request