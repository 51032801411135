import axios from './$http'


// 分页查询推荐
export function recommendList(data) {
  return axios.get("/ka-recommend", { params: data })
}
// 创建分类
export function setRecommend(params) {
  return axios.post("/ka-recommend", params)
}
// 根据id修改分类
export function putRecommend(data) {
  return axios.put("/ka-recommend/" + data.id, data)
}
// 根据id修改分类状态
export function putRecommendStatus(id, status) {
  return axios.put("/ka-recommend/" + id + "/status/" + status)
}
// 根据id查询分类详情
export function getRecommend(id) {
  return axios.get("/ka-recommend/detail/" + id)
}
// 根据id删除分类详情
export function deleteRecommend(id) {
  return axios.delete("/ka-recommend/" + id)
}