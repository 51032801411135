import axios from './$http'
//首页最近7日、30日Pv Uv 数据接口
export function recentlyUP(data) {
    return axios.get("/stat/uv-pv/hospital/recently-uvpv",{ params: data })
}
//当日新增用户数及同比、过期一周数据
export function dayUp(){
    return axios.get('/stat/uv-pv/hospital/today-uv-new-detail')
}

//当日访问量及同比、过期一周数据
export function dayPv(){
    return axios.get('/stat/uv-pv/hospital/today-pv-detail')
}