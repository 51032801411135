
let ConfigBaseURL

if (/healthcaretest/.test(window.location.hostname)) {
  ConfigBaseURL = "https://healthcaretest.yunxiaoos.com/api"
} else if (/healthcare/.test(window.location.hostname)) {
  ConfigBaseURL = "https://healthcare.yunxiaoos.com/api"
} else {
  // ConfigBaseURL = "http://172.16.10.175:8000"
  // ConfigBaseURL = "https://healthcare.yunxiaoos.com/api"
  // ConfigBaseURL = "https://healthcaretest.yunxiaoos.com/api"
  ConfigBaseURL = "/api"

}
const config = {
  hostUrl: ConfigBaseURL
};
// http://localhost:3000/api/kaweb/wx/getUnlimitedpage=pages/curriculum/livingDetail/livingDetail&scene=1,9,2

export default config
