import React, { Component } from 'react';
import { Layout } from 'antd';
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { menus } from './compute.js';
import { pathConversionArr } from '../../libs/until.tool';
import utils from '../../libs/utils';
import Menus from '../../components/Menus';
import Logo from '../../components/logo';
import HeaderBar from '../../components/Header';
import RenderRoutes from '../../router'
import BreadcrumbCom from '../../components/BreadcrumbCom';

import './index.less';


const { Header, Content, Footer, Sider } = Layout
@inject("appStore")
@observer
class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
        }
    }
    componentWillMount() {

        const { initMenus } = this.props.appStore
        initMenus(menus)
    }
    componentDidMount() {

        let token = utils.getCookies("token")
        if (!token) {
            this.props.history.push("/login")
        }
        const { changeBreadcrumbList } = this.props.appStore
        let { pathname } = this.props.location
        let pathList = pathConversionArr(pathname, false)
        changeBreadcrumbList(pathList)
    }
    onToggle = () => {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }
    render() {
        const { appStore } = this.props
        const { hospitalInfo } = appStore
        const { hospitalLogo } = toJS(hospitalInfo)
        let breadcrumbList = toJS(appStore.breadcrumbList)
        return (
            <Layout
                breakpoint="lg"
            >
                <Sider collapsed={this.state.collapsed} style={{ height: '100vh', overflowY: 'auto', backgroundColor: "#fff" }}>
                    <div className="menu-nav">
                        <Logo collapsed={this.state.collapsed} src={hospitalLogo ? hospitalLogo : require("../../assets/images/logo.png")}>健康教育管理平台</Logo>
                        <Menus menus={menus} ></Menus>
                    </div>
                </Sider>
                <Layout className="site-layout" style={{ height: '100vh', overflowY: 'auto' }} id="wrap">
                    <div>
                        <Header className="site-layout-background">
                            <HeaderBar collapsed={this.state.collapsed} onToggle={this.onToggle}></HeaderBar>
                        </Header>
                        <Content className="container">
                            <BreadcrumbCom list={breadcrumbList}></BreadcrumbCom>
                            <div style={{ minHeight: "calc(100vh - 200px)" }}>
                                <RenderRoutes />
                            </div>
                            <Footer style={{ textAlign: "center" }}>健康教育管理平台（推荐使用桌面版谷歌或 360 浏览器） 技术支持：中妇育教</Footer>
                        </Content>
                    </div>
                </Layout>
            </Layout>
        );
    }
}

export default index;
