import { observable, action, computed } from 'mobx';
import { HashRouter } from 'react-router-dom'
import { flatten } from '../libs/until.tool';
import utils from '../libs/utils';
const router = new HashRouter()
class appStore {
  @observable breadcrumbList = []
  @observable userInfo = {}
  @observable hospitalInfo = {}
  @observable menus = []

  @action initHospitalInfo = (hospitalInfo) => {
    this.hospitalInfo = hospitalInfo
  }
  @action changeBreadcrumbList = (pathList, hashome = true) => {
    let breadcrumbList = []
    pathList.forEach(path => {
      breadcrumbList = breadcrumbList.concat(flatten(this.menus, "subs", true).filter(item => item.key === path))
    })
    // if (hashome && breadcrumbList[0].title !== "首页") {
    //   breadcrumbList.unshift({ title: "首页", to: "/home" })
    // }
    this.breadcrumbList = breadcrumbList
  }
  @action login = (userInfo, token) => {
    this.userInfo = userInfo
    utils.setCookies("userInfo", userInfo)
    utils.setCookies("token", token)
  }
  @action loginOut = () => {
    console.log("out");
    router.history.push('/login')
    this.userInfo = {}
    utils.removeCookies("userInfo")
    utils.removeCookies("token")
  }
  @action initMenus = (menus) => {
    this.menus = menus
  }
  @computed get getSubMenusKeys() {
    let menusList = flatten(this.menus, "subs", true)
    return menusList.filter(item => item.subs).map(item => item.key)
  }
  @computed get menusLen() {
    return this.menus.length
  }
}
export default new appStore()