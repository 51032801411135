

import React from 'react';
let icon = <i style={{ opacity: 0 }} className="iconfont iconkechengguanli"></i>

export const menus = [
    {
        title: '首页',
        icon: 'iconshouye',
        key: '/home'
    },
    {
        title: '内容管理',
        key: '/content',
        icon,
        subs: [
            { key: '/content/carousel', title: '课程管理', icon: 'iconkechengguanli', },
            { key: '/content/knowledge', title: '文章管理', icon: 'iconwenzhangguanli' },
            { key: '/content/message', title: '消息管理', icon: 'iconxiaoxiguanli' },
            { key: '/content/recommend', title: '推荐管理', icon: 'icontuijianguanli' }
        ]
    },
    // {
    //     title: '用户资料',
    //     key: '/user',
    //     icon,
    //     subs: [
    //         { key: '/user/user-info', title: '用户信息', icon: 'iconyonghuxinxi', },
    //         { key: '/user/appointment', title: '表单采集', icon: 'iconbiaodancaiji', },
    //     ]
    // },
    // {
    //     title: '数据统计',
    //     key: '/operate',
    //     icon,
    //     subs: [
    //         { key: '/operate/video-daily', title: '课程数据', icon: 'iconkechengshuju', },
    //         { key: '/operate/underLineStatistics', title: '文章数据', icon: 'iconwenzhangshuju' }
    //     ]
    // },
    {
        title: '基础设置',
        key: '/seting',
        icon,
        subs: [
            { key: '/seting/column', title: '栏目设置', icon: 'iconlanmushezhi', },
            { key: '/seting/tags', title: '首页标签', icon: 'iconshouyebiaoqian', },
            { key: '/seting/lecturer', title: '讲师设置', icon: 'iconjiangshishezhi', },
            // { key: '/seting/organization', title: '机构资料', icon: 'iconjigouziliao', },
        ]
    },
]