import axios from './$http'

// 查询模板列表
export const getTemplateList = (params) => {
  return axios.get("/ka-course-template", { params })
}
// 查询模板详情
export const getTemplateDetail = (id) => {
  return axios.get("/ka-course-template/detail/" + id)
}
// 删除模板
export const deleteTemplateDetail = (id) => {
  return axios.delete("/ka-course-template/" + id)
}